<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      formFunctionCode: 'kms_direct_stores_form',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'bsDirectSystemId') {
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemId' };
        item.headers = { functionCode: 'direct_marketing_system_list' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
      }
      if (item.field === 'terminalName') {
        item.restful = '/mdm/mdmTerminalController/listCondition';
        item.optionsKey = { label: 'terminalName', value: 'terminalName' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'terminalName',
        };
      }
      if (item.field === 'storeId') {
        item.restful = '/kms/kmsAdmin/tenantry/direct/store/list';
        item.optionsKey = { label: 'storeName', value: 'id' };
        item.restfulParams = {
          enableStatus: '009',
        };
        // 获取第一步的id
        item.restfulParamsGetValue = { directId: 'directId' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'storeName',
        };
      }
      // if (item.field === 'storeCode') {
      //   item.restful = '/kms/kmsAdmin/tenantry/direct/store/list';
      //   item.optionsKey = { label: 'storeName', value: 'storeCode' };
      //   item.restfulParams = {
      //     enableStatus: '009',
      //   };
      //   // 获取第一步的id
      //   item.restfulParamsGetValue = { directId: 'directId' };
      //   item.props = {
      //     ...item.props,
      //     filterable: true,
      //     remote: true,
      //     remoteParams: 'storeName',
      //   };
      // }
      // 对账时间阈值
      if (item.field === 'timeOfDay') {
        item.props = {
          ...item.props,
          step: 1,
          precision: 0,
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      const bsDirectSystemId = this.getRule('bsDirectSystemId');
      bsDirectSystemId.on.change = (e) => {
        const selectedData = bsDirectSystemId.options.find((item) => item.bsDirectSystemId === e);
        this.setValue({
          bsDirectSystemName: selectedData.bsDirectSystemName,
          bsDirectSystemCode: selectedData.bsDirectSystemCode,
          directId: selectedData.directId,
        });
      };
      const terminalName = this.getRule('terminalName');
      terminalName.on.change = (e) => {
        const selectedData = terminalName.options.find((item) => item.terminalName === e);
        this.setValue({
          terminalCode: selectedData.terminalCode,
        });
      };
      const storeId = this.getRule('storeId');
      storeId.on.change = (e) => {
        const selectedData = storeId.options.find((item) => item.id === e);
        this.setValue({
          storeName: selectedData.storeName,
          storeCode: selectedData.storeCode,
        });
      };
      // const storeCode = this.getRule('storeCode');
      // storeCode.on.change = (e) => {
      //   const selectedData = storeCode.options.find((item) => item.storeCode === e);
      //   this.setValue({
      //     storeName: selectedData.storeName,
      //     storeId: selectedData.id,
      //   });
      // };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        console.log(this.formConfig.row);
        this.setValue(
          {
            ...this.formConfig.row,
            confirmPass: this.formConfig.row.loginPass,

          },

        );
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/kms/kmstenantrydirectstore/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/kms/kmstenantrydirectstore/update';
          params.id = this.formConfig.row.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
